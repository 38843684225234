/**
 * locationに含まれるアプリ固有のパラメーター種別を定数として定義
 * @module app/consts/PUB_STATUS
 */
define({
    /**
     **一時保存
     * ・全ユーザーが登録可能
     * ・登録者本人のみ閲覧化
     */
    SELF: '0',

    /**
     **組織内共有
     * ・全ユーザーが登録可能
     * ・deptCd, sectCd, unitCdの全てが一致しているユーザのみ閲覧可能
     */
    ORGANIZATION: '1',

    /**
     **市町村内管理
     * ・市町村ユーザが自市町村CDで登録する
     * ・レイヤの市町村の市町村ユーザのみ閲覧可能
     **県庁内管理
     * ・県・振興局ユーザが県の市町村CDで登録する
     * ・県・振興局ユーザのみ閲覧可能
     */
    MUNICIPALITY: '2',

    /**
     **県報告
     * ・市町村・その他ユーザーが登録可能
     * ・市町村ユーザーは自市町村のみ閲覧可能
     * ・振興局ユーザーは管内市町村またはその他ユーザーが登録したレイヤのみ閲覧可能
     * ・県ユーザーは全て閲覧可能
     * ・その他ユーザーはdeptCd, sectCd, unitCdの全てが一致しているレイヤのみ閲覧可能
     */
    PREFECTURE: '3',

    /**
     **システム管理+管理危機管理対策室(管理+一般)ユーザのみ
     */
    MANAGEMENT: '4',

    /**
     **全体公開
     * ・全ユーザーが登録・閲覧可能
     */
    ALL: '9'
});
