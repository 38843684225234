/**
 * 権限の種類を定数として定義
 */
define({
    // 特権管理者権限
    CHIEF_ADMIN: 'CHIEF_ADMIN',
    // 管理者権限
    ADMIN: 'ADMIN',
    // 書込権限
    WRITE: 'WRITE',

    // 管理ユーザ権限
    ADMIN_USER: 'R01001',
    // 全体管理者
    SYSMGR_USER: 'R01011',
    // 市本部管理者
    SHMGR_USER: 'R01031',
    // 所属管理者
    XXMGR_USER: 'R01021',

    // 市本部ユーザ
    SHIHONBU_USER: 'R02012',
    // 区本部ユーザ
    KUHONBU_USER: 'R02022',
    // 所属本部ユーザ
    XXHONBU_USER: 'R02032',

    // 一般職員ユーザ
    IPPAN_USER: 'R03012',
    // 応援職員ユーザ
    AID_USER: 'R03022',
    // 自主防災組織ユーザ
    VPD_USER: 'R03032',
    // 福祉避難所ユーザ
    WEL_USER: 'R03042',
    // 施設管理者ユーザ
    FAC_USER: 'R03072',
    // 下水処理事業者ユーザ
    SEW_USER: 'R03082',
    // 道路管理事業者ユーザ
    ROA_USER: 'R03092',
    // 参照権限ユーザ
    REF_USER: 'R03102',

    // 広報担当ユーザ
    PUB_USER: 'R03202'
});
