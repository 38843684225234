/**
 * ユーザ種別の定数定義
 */
define({
    // 大阪市ユーザ
    PREFECTURE: '01',
    // 振興局ユーザ
    REGION: '05',
    // 区・一般ユーザ
    MUNICIPALITY: '03',
    // 関係機関ユーザ
    OTHER_ORGAN: '04'
});
